import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'
import { FiArrowRight } from 'react-icons/fi'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Not Found" />
        <div className="not-found">
          <div className="not-found-type">
            <h1>Page not found</h1>
            <p>The page you requested does not exist.</p>

            <Link to="/" className="button primary">
              Go home <FiArrowRight />
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage
